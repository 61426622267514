import React from "react";
// Customizable Area Start

import { createTheme , styled} from "@material-ui/core/styles";
import { Empty, active_filter } from "./assets";
import {
  Grid,
  Box,
  Button,
  Typography,
  Modal,
} from "@material-ui/core";
export const configJSON = require("./config");
// Customizable Area End
import DashboardController, { Props } from "./DashboardController";


export const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  },
});


export default class Template extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const DuplicatCard = () => {
      return (
        <div>
          <Modal
            open={this.state.isduplicateOpen}
            aria-labelledby="email-verified-title"
            aria-describedby="email-verified-description">
            <Box
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '420px',
                backgroundColor: 'white',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                padding: '20px 5px 20px 5px'
              }}>
              <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography style={{ color: 'black' }}>
                    Do you really want to duplicate <span style={{ fontWeight: 'bold', color: 'black' }}>Developer</span>
                    <Typography style={{ fontWeight: 'bold', color: 'black', display: 'flex', justifyContent: 'center' }}>
                      Gamecard
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    paddingLeft: '40px',
                    paddingRight: '40px',
                    marginTop: '25px'
                  }} >
                  <Button
                    style={{
                      backgroundColor: '#FDD100',
                      color: 'black',
                      marginRight: '10px',
                      width: '80px',
                      fontSize: 'smaller',
                      fontWeight: 250,
                      textTransform: 'none'
                    }}
                    data-testid="singleuser"
                    onClick={() => { this.navigateToTemplateAdded()}}>
                    Yes
                  </Button>
                  <Button
                    variant="outlined"
                    style={{
                      backgroundColor: 'white',
                      color: 'black',
                      width: '80px',
                      fontSize: 'smaller',
                      fontWeight: 250,
                      textTransform: 'none'
                    }}
                    data-test-id="closeBtn"
                    onClick={this.handleClose2}>
                    No
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </div>
      );
    };

    return (
      <>
        <div className="content-wrapper">
          <form>
            <div className="form-group" style={webStyle.gapHeader}>
              <label style={{ paddingLeft: "10px" , fontSize: "18px",  marginTop: "12px" , fontFamily: 'Open Sans',}}>Choose Department <span style={webStyle.mandatory}>*</span></label>
              <div className="control">
                <StyledModalContainer>
                <Box style={{width: "233px" ,backgroundColor: "F6F6F9" , borderRadius: "12px"}}>
                  <select data-testId="selectOption" style={{border:"none",background:"none",padding:"15px" , width: "220px"}}>
                    <option  value="choose department">All Department</option>
                    <option  value="development">development</option>
                    <option  value="It">It</option>
                    <option  value="QA">QA</option> 
                    <option  value="Mahima">Mahima</option>
                  </select>
                </Box>
                </StyledModalContainer>
              </div>
            </div>
          </form>
          <div className="partner-heading">
            <form>
              <div style={webStyle.filterHeader}>
                <label style={{fontSize:18}}>All Templates</label>
                <div className="control">
                  <img src={active_filter} style={webStyle.imgTemplate} />
                </div>
              </div>
            </form>
          </div>
          <div className="partner-left">
            {!this.state.showKpiDiv ? (
              <div className="no-records-alert">
                <img src={Empty} alt="logo" width={175} height={125} />
                <h3>No Records Found!</h3>
                <h5>There are no records to display right now.</h5>
              </div>
            ) : (
              <div className="partner-filter-data">
                <div className="template-search-result">
                  <ul>
                    <div
                      style={webStyle.main}
                      data-test-Id="templateList"
                      data-test-id="list">
                      <Grid container spacing={1} style={webStyle.Grid1}>
                        <Grid item xs={11}>
                          <Typography
                            style={webStyle.Typo1}
                            data-test-id="templateId"
                          >
                            {configJSON.template}
                          </Typography>
                          <Typography style={webStyle.Typo2}>
                            {configJSON.testingDesc}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <div
                            className="result-action"
                            style={webStyle.grid3 as React.CSSProperties} >
                            <button
                              style={{ cursor: "pointer" }}
                              className="button"
                              onClick={this.toggleModal}
                              data-testid="duplicateButton"
                            >
                              Duplicate
                            </button>
                            <a href="#" className="view-link">View Details</a>
                          </div>
                        </Grid>
                      </Grid>
                      {DuplicatCard()}
                    </div>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );

   
  }
}

// Customizable Area Start
const webStyle = {
  Grid1: {
    backgroundColor: "#F6F6F9",
    padding: "16px",
    borderRadius: "11px",
    marginLeft: "13px",
    width: "98%"
  },
  addFeatureButton: {
    backgroundColor: "#f7da08",
    height: "50px",
    width: "26%",
    fontWeight: "bold",
    borderRadius: "13px"
  },
  main: {
    cursor: "pointer",
    borderRadius: "11px",
    margin: "21px",
  },
  footerStyle: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "1rem",
    paddingTop: "50px"
  },
  buttonsubmit: {
    backgroundColor: "#f7da08",
    borderRadius: "7px",
    width: "19%"
  },
  mandatory: { 
    color: "red" 
  },
  gapHeader: { 
  gap: "20px", 
  margin: "20px" 
 },
  filterHeader: { 
  padding: "11px 0px 0px 22px", 
  display: "flex", 
  alignItems: "flex-start", 
  gap: "20px",
  fontFamily: 'Open Sans',
 },
  imgTemplate: {
    height: "23px"
  },
  buttoncancel: {
    paddingLeft: "18px"
  },
  root: {
    maxWidth: 500,
    maxHeight: 90,
    marginRight: "48px",
    marginLeft: "38px"
  },
  Typo1: {
    color: 'black',
    fontWeight: 700,
    fontSize: 20,
    fontFamily: 'Open Sans',
  },
  Typo2: {
    color: 'black',
    lineHeight: 2.5,
    fontSize: 16,
    fontFamily: 'Open Sans',
  },
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  dropdown: {
    minWidth: 120
  },
  search: {
    position: "relative",
    backgroundColor: "white",
    marginRight: "48px",
    marginLeft: "38px",
    borderRadius: "10px",
    border: "2px solid #e6e8ea",
    width: "450px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    right: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#95969f"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 0, 1, 1),
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch"
    }
  },
  grid3: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center"
  }
};

const StyledModalContainer = styled(Box)({
  
  '@media (max-width: 1370px)': {
    width: "170px",
    
},
})
// Customizable Area End