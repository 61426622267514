import React from "react";
// Customizable Area Start
import {
  Button,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Divider,
  Box
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// Customizable Area End
import AddDkrController, { Props } from "./AddDkrController";
export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",
    }
  },
  typography: {
    subtitle1: {
      margin: "20px 0px"
    },
    h6: {
      fontWeight: 500
    },
  }
});
export default class AddDkr5 extends AddDkrController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box style={webStyle.maincontainer}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid className="first" style={webStyle.leftSide} item>
              <Grid container spacing={2} alignItems="center">
                <Grid item style={webStyle.rowgridDisplay}>
                  <ArrowBackIosIcon style={{ fontSize: "23px" , color: "black" }} data-test-id="nextButton" onClick={this.navigateToEditTemplate2}/>
                  <span
                    style={{...webStyle.labelStylesecond,fontWeight:400}}
                  >
                    Edit Template
                  </span>
                </Grid>
                <Grid item style={webStyle.rowgridDisplay}>
                  <ArrowBackIosIcon style={{...webStyle.labelStylesecond,color:"#B2B2B2"}} />
                  <span style={{...webStyle.labelStyle100 as React.CSSProperties,fontWeight:700,fontSize:"18px"}}>
                    Add DKR Details
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="second" style={webStyle.nullContainer} item />
          </Grid>
          <Grid
            container
            alignItems="center"
            style={webStyle.informContainer as React.CSSProperties}
          >
            <Grid
              className="first"
              item
              style={webStyle.informationSubContainerHeading}
              xs={12}
            >
              <Box>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.headermandatory}>
                    <span style={webStyle.labelStyle22 as React.CSSProperties}>KPI Details</span>
                  </FormLabel>

                </FormControl>
              </Box>
              <Box className="form-field text-center" id="signupBlock4">
                <Box className="control" id="signupfieldcontrol2">
                <Button style={{ fontFamily: 'Open Sans', backgroundColor: "FDD100", color: "black", padding:"15px 20px 15px 20px",borderRadius:"10px", width: "200px", fontSize: "16px", fontWeight: 600, textTransform: "none" }} data-test-id="nextButton1" onClick={this.navigateToEditKpi}>
                Edit KPI Details
            </Button>
                </Box>
              </Box>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.informSubContainer}
              xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.headermandatory}>
                  <span style={{...webStyle.labelStyle100 as React.CSSProperties,fontSize:"22px"}}>Type</span>
                </FormLabel>
                <span style={webStyle.mainvalueStyle as React.CSSProperties}>Core</span>
              </FormControl>
            </Grid>
            <Grid
              className="second"
              item
              style={webStyle.informSubContainer}
              xs={12} >
              <FormControl component="fieldset">
                <FormLabel component="legend" style={{...webStyle.headermandatory,marginTop:"40px"}}>
                  <span style={webStyle.labelStyle100 as React.CSSProperties}>Basic Details</span>
                </FormLabel>
                <Grid container style={{ display: "flex" }} xs={12}>
                  <Grid item xs={3}>
                    <Box className="form-block" id="signupBlock3">
                      <form>
                        <Box
                          className="form-field"
                          id="signupform"
                          style={webStyle.top30}  >
                          <span style={webStyle.labelStylemainHeader as React.CSSProperties}>Title</span>
                          <Box
                            className="control"
                            id="signupfieldcontrol"
                            style={webStyle.marginLeft0}  >
                            <span style={webStyle.mainvalueStyle as React.CSSProperties}>KPI-5</span>
                          </Box>
                        </Box>
                      </form>
                    </Box>
                  </Grid>
                    <Grid item xs={6}>
                      <Box className="form-block" id="signupBlock3">
                        <form>
                        <Box
                          className="form-field"
                          id="signupform"
                          style={webStyle.top30}  >
                          <span style={webStyle.labelStylemainHeader as React.CSSProperties}>Description</span>
                          <Box
                            className="control"
                            id="signupfieldcontrol"
                            style={webStyle.marginLeft0}  >
                            <span style={webStyle.mainvalueStyle as React.CSSProperties}>Weekly Stock<br />Check Stock for 2 Items<br />Saturdays</span>
                          </Box>
                          </Box>
                        </form>
                      </Box>
                    </Grid>
                  <Grid item xs={3}>
                    <Box className="form-block" id="signupBlock3">
                      <form>
                        <Box
                          className="form-field"
                          id="signupform"
                          style={{...webStyle.top30,width:"max-content"}}   >
                          <span style={webStyle.labelStylemainHeader as React.CSSProperties}>Points (Weightage)</span>
                          <Box
                            className="control"
                            id="signupfieldcontrol"
                            style={webStyle.marginLeft0}  >
                            <span style={webStyle.mainvalueStyle as React.CSSProperties}>10 points</span>
                          </Box>
                        </Box>
                      </form>
                    </Box>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid >
          </Grid >
          <Divider light />
          <Grid
            container
            alignItems="center"
            style={webStyle.informContainer as React.CSSProperties}  > 
          <Grid
              className="first"
              item
              style={webStyle.informationSubContainerHeading}
              xs={12}  >
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.headermandatory}>
                    <span style={webStyle.labelStyle22 as React.CSSProperties}>Daily KPI Report</span>
                  </FormLabel>
                </FormControl>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.informationSubContainerTable}
              xs={12}  >
              <>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.headermandatory}>
                    <span style={{...webStyle.labelStyle100 as React.CSSProperties,fontWeight:500,marginTop:"30px"}}>Applicable DKRs</span>
                  </FormLabel>
                </FormControl>
                <TableContainer style={webStyle.tablemainContainer}>
                  <Table aria-label="customized table">
                  <TableHead>
                      <TableRow style={webStyle.maintableHead}>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",paddingLeft:"35px"}}><span style={{fontWeight:600}}>Sr.</span><span style={{fontWeight:600}}>No.</span></TableCell>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",fontWeight:600}}>DKR Brief Title</TableCell>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",fontWeight:600}}>
                          DKR Response
                        </TableCell>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",fontWeight:600}}>
                          DKR Response
                        </TableCell>
                        <TableCell>
                           <Button style={{ fontFamily: 'Open Sans', backgroundColor: "FDD100", color: "black", marginRight: "20px", width: "160px", fontSize: "16px", fontWeight: 600, textTransform: "none",paddingTop: "6px",paddingBottom:"6px",borderRadius:"10px"  }}>
                               Add DKR
                           </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow 
                        >
                          <TableCell component="th" scope="row" style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",paddingLeft:"65px"}}>
                            1.
                          </TableCell>
                          <TableCell>
                            <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    id="signupfieldcontrol"
                                    style={webStyle.marginLeft0}
                                  >
                                    <input
                                      type="text"
                                      data-test-id="txtInputEmail"
                                      style={{...webStyle.inputmainFieldTable,width:"100%",color:"Black",textAlign:"start",fontFamily:"Open Sans",fontWeight:400,fontSize:"14px"}}
                                      className="control-input"
                                      placeholder="Today's Stock"
                                      name="email"
                                      id="pronounce-control"
                                    />
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    id="signupfieldcontrol"
                                    style={webStyle.myBox2}
                                  >
                                    <select style={{...webStyle.mySelect,color:"Black",textAlign:"start",fontFamily:"Open Sans",fontWeight:400,fontSize:"14px"}}>
                                       <option value="Manual">Manual</option>
                                       <option value="Dropdown">Dropdown</option>
                                    </select>
                                  </Box>

                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell>
                          </TableCell>
                          <TableCell>
                          <a href="#" style={{display:"block",color:"#ff5c5c",textDecoration:"underline",fontSize:"16px",paddingLeft:"40px",fontWeight:400,fontFamily:"Open Sans"}}>Remove DKR</a>
                          </TableCell>
                        </TableRow>
                        <TableRow 
                        >
                          <TableCell component="th" scope="row" style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",paddingLeft:"65px"}}>
                           2.
                          </TableCell>
                          <TableCell>
                            <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    id="signupfieldcontrol"
                                    style={{marginLeft: 0, marginTop: 10}}
                                  >
                                    <input
                                      type="text"
                                      data-test-id="txtInputEmail"
                                      style={{...webStyle.inputtext,color:"Black",textAlign:"start",fontFamily:"Open Sans",fontWeight:400,fontSize:"14px"}}
                                      className="control-input"
                                      placeholder="Average Stock"
                                      name="email"
                                      id="pronounce-control"
                                    />
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    id="signupfieldcontrol"
                                    style={webStyle.myBox}
                                  >
                                    <select style={{...webStyle.mySelect,color:"Black",textAlign:"start",fontFamily:"Open Sans",fontWeight:400,fontSize:"14px"}}>
                                       <option value="Manual">Dropdown</option>
                                       <option value="Dropdown">Manual</option>
                                    </select>
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell style={{width:"24%"}}>
                          <a href="#" className="view-link" style={{color:"#1c33ad",fontSize:"16px",fontWeight:400,fontFamily:"Open Sans",width:"fit-content"}}>Add Option</a>
                          </TableCell>
                          <TableCell>
                          <a href="#" style={{display:"block",color:"#ff5c5c",textDecoration:"underline",fontSize:"16px",paddingLeft:"40px",fontWeight:400,fontFamily:"Open Sans"}}>Remove DKR</a>
                          </TableCell>
                        </TableRow>
                        <TableRow 
                        style={{ backgroundColor: "#F6F6F9" }}>
                          <TableCell component="th" scope="row" style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",paddingLeft:"30px"}}>
                           Option 1.
                          </TableCell>
                          <TableCell>
                            <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    id="signupfieldcontrol"
                                    style={webStyle.marginLeft0}
                                  >
                                    <input
                                      type="text"
                                      data-test-id="txtInputEmail"
                                      style={{...webStyle.inputmainFieldTable,backgroundColor:"White",width:"100%",color:"Black",textAlign:"start",fontFamily:"Open Sans",fontWeight:400,fontSize:"14px"}}
                                      className="control-input"
                                      placeholder="Recorded stock"
                                      name="email"
                                      id="pronounce-control"/>
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box 
                             id="signupBlock3"
                            className="form-block">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    style={webStyle.marginLeft0} 
                                    id="signupfieldcontrol" >
                                     <Button style={webStyle.Removebutton as React.CSSProperties}>
                                         Remove
                                     </Button>
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow 
                        style={{ backgroundColor: "#F6F6F9" }} >
                          <TableCell component="th" scope="row" style={{...webStyle.Tcell,paddingLeft:"30px"}}>
                            Option 2.
                          </TableCell>
                          <TableCell>
                            <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    id="signupfieldcontrol"
                                    style={webStyle.marginLeft0}
                                  >
                                    <input
                                      type="text"
                                      data-test-id="txtInputEmail"
                                      style={{...webStyle.inputmainFieldTable,backgroundColor:"white",width:"100%",color:"Black",textAlign:"start",fontFamily:"Open Sans",fontWeight:400,fontSize:"14px"}}
                                      className="control-input"
                                      placeholder="Current stock"
                                      name="email"
                                      id="pronounce-control"/>
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box 
                            className="form-block"
                             id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform">
                                  <Box
                                      style={webStyle.marginLeft0} 
                                      id="signupfieldcontrol"
                                    className="control">
                                     <Button style={webStyle.Removebutton as React.CSSProperties}>
                                         Remove  </Button>
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            </Grid>
          </Grid >
          <Grid style={webStyle.buttonContainer as React.CSSProperties}>
          <Box style={{ marginTop: '50px',marginBottom: '40px' }}>
            <Button style={webStyle.SubmitBtn as React.CSSProperties}>
              Submit
            </Button>
            <Button variant="outlined" style={webStyle.CancelBtn as React.CSSProperties} >
              Cancel
            </Button>
          </Box>
          </Grid>
        </Box >
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  CancelBtn: {
    fontFamily: 'Open Sans', 
    fontWeight: 600, 
    fontSize: "16px", 
    width: "170px", 
    backgroundColor: "white", 
    textTransform: "none",
    color: "black", 
  },
  SubmitBtn:{
    backgroundColor: "FDD100", 
    fontFamily: 'Open Sans', 
    marginRight: "20px",
    color: "black", 
    fontSize: "16px", 
    width: "170px", 
    textTransform: "none" ,
    fontWeight: 600, 
  },
  Tcell:{
    fontFamily:"Open Sans",
    fontSize:"14px",
    color:"black"
  },
  Removebutton:{
    fontFamily: 'Open Sans',
    border:"1px solid #ff5c5c", 
    backgroundColor: "White", 
    color: "black", 
    width: "145px", 
    fontSize: "16px",
    fontWeight: 600, 
    textTransform: "none",
    paddingTop: "12px",
    paddingBottom:"12px",
    borderRadius:"10px"
  },
  myBox2:{
    marginTop: 10,
    width: "145px" ,
    bottom:"6px",
    border:"1px solid #979797", 
    marginLeft: 0,
    borderRadius: "12px",
  },
  mySelect:{
    border:"none",
    background:"none",
    padding:"15px",
     width:"135",
  },
  myBox:{
    border:"1px solid #979797",
    width: "145px",
    bottom:"6px",
    borderRadius: "12px",
    marginTop: 10, 
    marginLeft: 0,
  },
  inputtext:{
    border: '0.5px solid #979797',
    background: 'transparent', 
 width:"100%"
  },
   maincontainer: {
     height: "64px",
     marginRight: "-20px",
     background: "#F3F4F5",
  },
  nullContainer: { 
    padding: "0px 44px 0px 0px" 
  },
  leftSide: {
    padding: "19px 0px 21px 38px"
  },
  informContainer: { 
    padding: "0 32px", 
    alignItems: "baseline", 
    flexDirection: "column" 
  },
  informSubContainer: { 
    width: "800px", 
    marginTop: "24px", 
  },
  informationSubContainerTable: {
    width: "90%" ,
    marginTop: "24px",
    },
  informationSubContainerHeading: { 
    display: "flex", 
    marginTop: "24px",
    justifyContent: "space-between", 
    alignItems: "initial", 
     width: "90%" 
    },
  container: {
    width: "100%",
    height: "100%"
  },
  top30: { marginTop: "30px" },
  labelStyle100: {
    fontSize: "22px",
    color: "black",
    fontFamily:"Open Sans",
    fontWeight: 500,
  },
  labelStylemainHeader: {
    fontSize: "18px",
    color: "#c8ccc9",
    fontFamily:"Open Sans",
    fontWeight: 700,

  },
  mainvalueStyle: {
    color: "black",
    fontSize: "16px",
    marginTop: "10px",
    fontFamily:"Open Sans",
    fontWeight: 400
    },
 
  labelStylesecond: {
    fontSize: "18px",
    color: "black",
    fontFamily:"Open Sans"
  },
  labelStyle22: {
    fontSize: "24px",
    color: "black",
    fontFamily:"Open Sans",
    fontWeight: 700,
  },
  rowgridDisplay: { display: "flex", alignItems: "center" },
  inputmainFieldTable: {
    background: 'transparent',
    width: "70%",
    border: '0.5px solid #979797'
  },

  marginLeft0: {
    marginLeft: 0,
    marginTop: 10
  },
  buttonContainer: { 
     display: "flex",
     margin: "0 40px" ,
     gap: "20px", 
    },
  headermandatory: {
    alignItems: "baseline",
    display: "flex",
    MarginTop:"30px",
    gap: "4px",
  },
  tablemainContainer: { 
    marginTop: "15px" ,
    border: "1px solid #979797",
     borderRadius: "10px", 
    },
  maintableHead: {
    backgroundColor: "#F5F5F5",
  },

};
// Customizable Area End
