import React from "react";
// Customizable Area Start
import {
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Divider,
  Box
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// Customizable Area End
import AddDkrController, { Props } from "./AddDkrController";
export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",
    }
  },
  typography: {
    subtitle1: {
      margin: "20px 0px"
    },
    h6: {
      fontWeight: 500
    },
  }
});
export default class AddDkr extends AddDkrController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { typing, Dropdown } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box style={webStyle.maincontainer}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid className="first" style={webStyle.leftSide} item>
              <Grid container spacing={2} alignItems="center">
                <Grid item style={webStyle.rowarrowDisplay}>
                  <ArrowBackIosIcon style={{ fontSize: "24px", color: "black" }} data-test-id="nextButton1" onClick={this.navigateToEditTemplate2} />
                  <span
                    style={{...webStyle.HeadStyleTop1,fontWeight: 400}}>
                    Edit Template
                  </span>
                </Grid>
                <Grid item style={webStyle.rowarrowDisplay}>
                  <ArrowBackIosIcon style={{...webStyle.HeadStyleTop1,color:"#B2B2B2",fontSize:"16px"}} />
                  <span style={{...webStyle.labelStyleoptionhead as React.CSSProperties,fontWeight:700,fontSize:"18px"}}>
                    Add DKR Details
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="second" style={webStyle.nullContainer} item />
          </Grid>
          <Grid
            container
            alignItems="center"
            style={webStyle.informContainer as React.CSSProperties}>
            <Grid
              className="first"
              item
              style={webStyle.informSecondContainerHead}
              xs={12}>
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.headerMain10}>
                    <span style={webStyle.HeadtopStyle22 as React.CSSProperties}>KPI Details</span>
                  </FormLabel>

                </FormControl>
              </div>
              <div className="form-field text-center" id="signupBlock4">
                <div className="control" id="signupfieldcontrol2">
                  <Button style={webStyle.Editbutton as React.CSSProperties} data-test-id="EditButton" onClick={this.navigateToEditKpi}>
                    Edit KPI Details
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.informationSubject}
              xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.headerMain10}>
                  <span style={{ ...webStyle.labelStyleoptionhead as React.CSSProperties, fontSize: "22px" ,fontWeight: 500}}>Type</span>
                </FormLabel>
                <span style={{...webStyle.blurvalueStyle as React.CSSProperties,fontWeight:400}}>Core</span>
              </FormControl>
            </Grid>
            <Grid
              className="second"
              item
              style={webStyle.informationSubject}
              xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={{ ...webStyle.headerMain10, marginTop: "40px" }}>
                  <span style={{...webStyle.labelStyleoptionhead as React.CSSProperties,fontWeight:500,fontSize:"22px"}}>Basic Details</span>
                </FormLabel>
                <Grid container style={{ display: "flex" }} xs={12}>
                  <Grid item xs={3}>
                    <div className="form-block" id="signupBlock3">
                      <form>
                        <div
                          className="form-field"
                          id="signupform"
                          style={webStyle.topdescThirty}>
                          <span style={webStyle.TopstyleHeader as React.CSSProperties}>Title</span>
                          <div
                            className="control"
                            id="signupfieldcontrol"
                            style={webStyle.marginLeft0}>
                            <Box className="custom-select" style={{ width: "100px", backgroundColor: "F6F6F9", borderRadius: "12px" }}>
                              <select style={{ padding: "8px", border: "none", background: "none", width: "90px" }}>
                                <option value="KPI1">KPI-1</option>
                                <option value="KPI2" data-test-id="DkrButton" onClick={this.navigateToAddDKR5}>KPI-5</option>
                              </select>
                            </Box>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Grid>
                  <>
                    <Grid item xs={6}>
                      <div className="form-block" id="signupBlock3">
                        <form>
                          <div
                            className="form-field"
                            id="signupform"
                            style={webStyle.topdescThirty}
                          >
                            <span style={webStyle.TopstyleHeader as React.CSSProperties}>Description</span>
                            <div
                              className="control"
                              id="signupfieldcontrol"
                              style={webStyle.marginLeft0}
                            >
                              <span style={{...webStyle.blurvalueStyle as React.CSSProperties,fontWeight:400}}>Sales Goal<br />Sales Goal : 1000000<br />Pro Rata Basis<br />MQR : 70%</span>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="form-block" id="signupBlock3">
                        <form>
                          <div
                            className="form-field"
                            id="signupform"
                            style={{...webStyle.topdescThirty,width:"max-content"}}
                          >
                            <span style={webStyle.TopstyleHeader as React.CSSProperties}>Points (Weightage)</span>
                            <div
                              className="control"
                              id="signupfieldcontrol"
                              style={webStyle.marginLeft0}
                            >
                              <span style={{...webStyle.blurvalueStyle as React.CSSProperties,fontWeight:400}}>20 points</span>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Grid>
                  </>
                </Grid>
              </FormControl>
            </Grid >

            <Grid
              className="third"
              item
              style={webStyle.informationSubject}
              xs={12}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" style={{...webStyle.headerMain10,marginTop: "40px"}}>
                  <span style={webStyle.labelStyleoptionhead as React.CSSProperties}>Calculation Type</span>
                </FormLabel>
                <div className="form-block" id="signupBlock3">
                  <form>
                    <div
                      className="form-field"
                      id="signupform"
                      style={webStyle.topdescThirty}
                    >
                      <span style={webStyle.TopstyleHeader as React.CSSProperties}>Automatic</span>
                      <div
                        className="control"
                        id="signupfieldcontrol"
                        style={webStyle.marginLeft0}
                      >
                        <span style={webStyle.blurvalueStyle as React.CSSProperties}>Yes</span>
                      </div>
                    </div>
                  </form>
                </div>
              </FormControl>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.informationSubject}
              xs={12}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" style={{...webStyle.headerMain10,marginTop: "40px"}}>
                  <span style={webStyle.labelStyleoptionhead as React.CSSProperties}>Monthly Goal Details</span>
                </FormLabel>
              </FormControl>
              <Grid container style={{ display: "flex" }} xs={12}>
                <Grid item xs={2}>
                  <div className="form-block" id="signupBlock3">
                    <form>
                      <div
                        className="form-field"
                        id="signupform"
                        style={webStyle.topdescThirty}
                      >
                        <span style={webStyle.TopstyleHeader as React.CSSProperties}>Goal Type</span>
                        <div
                          className="control"
                          id="signupfieldcontrol"
                          style={webStyle.marginLeft0}
                        >
                          <Box className="custom-select" style={{ width: "125px", backgroundColor: "F6F6F9", borderRadius: "12px" }}>
                            <select style={{ padding: "12px", border: "none", background: "none", width: "115px" }}
                              value={Dropdown}
                              onChange={this.handleCalculationDropdownChange}
                            >
                              <option value="Number">Number</option>
                              <option value="Percentage">Percentage</option>
                            </select>
                          </Box>
                        </div>
                      </div>
                    </form>
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <div className="form-block" id="signupBlock3">
                    <form>
                      <div
                        className="form-field"
                        id="signupform"
                        style={webStyle.topdescThirty}
                      >
                        <span style={webStyle.TopstyleHeader as React.CSSProperties}>DKR Calculation Formulas</span>
                        <div
                          className="control"
                          id="signupfieldcontrol"
                          style={webStyle.marginLeft0}
                        >
                          <span style={webStyle.blurvalueStyle as React.CSSProperties}>Sum</span>
                        </div>
                      </div>
                    </form>
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <div className="form-block" id="signupBlock3">
                    <form>
                      <div
                        className="form-field"
                        id="signupform"
                        style={webStyle.topdescThirty}
                      >
                        <span style={webStyle.TopstyleHeader as React.CSSProperties}>Goal Value (In numbers)</span>
                        <div
                          className="control"
                          id="signupfieldcontrol"
                          style={webStyle.marginLeft0}
                        >
                          <span style={webStyle.blurvalueStyle as React.CSSProperties}>1,000,000</span>
                        </div>
                      </div>
                    </form>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.informationSubject}
              xs={12}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" style={{...webStyle.headerMain10,marginTop: "40px"}}>
                  <span style={webStyle.labelStyleoptionhead as React.CSSProperties}>Analytics and Run rate</span>
                </FormLabel>
              </FormControl>
              <Grid container style={{ display: "flex", alignItems: "last baseline" }}>
                <Grid item xs={3}>
                  <div className="form-block" id="signupBlock3">
                    <form>
                      <div
                        className="form-field"
                        id="signupform"
                        style={webStyle.topdescThirty}
                      >
                        <span style={webStyle.TopstyleHeader as React.CSSProperties}>Run rate</span>
                        <div
                          className="control"
                          id="signupfieldcontrol"
                          style={webStyle.marginLeft0}
                        >
                          <span style={webStyle.blurvalueStyle as React.CSSProperties}>Yes</span>
                        </div>
                      </div>
                    </form>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="form-block" id="signupBlock3">
                    <form>
                      <div
                        className="form-field"
                        id="signupform"
                        style={webStyle.topdescThirty}
                      >
                        <span style={webStyle.TopstyleHeader as React.CSSProperties}>Part of Analytics</span>
                        <div
                          className="control"
                          id="signupfieldcontrol"
                          style={webStyle.marginLeft0}
                        >
                          <span style={webStyle.blurvalueStyle as React.CSSProperties}>Yes</span>
                        </div>
                      </div>
                    </form>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="form-block" id="signupBlock3">
                    <form>
                      <div
                        className="form-field"
                        id="signupform"
                        style={webStyle.topdescThirty}
                      >
                        <span style={webStyle.TopstyleHeader as React.CSSProperties}>Analytics Parameter</span>
                        <div
                          className="control"
                          id="signupfieldcontrol"
                          style={webStyle.marginLeft0}
                        >
                          <span style={webStyle.blurvalueStyle as React.CSSProperties}>Sales</span>
                        </div>
                      </div>
                    </form>
                  </div>
                </Grid>
              </Grid>
            </Grid>

          </Grid >
          <Divider light />
          <Grid
            container
            alignItems="center"
            style={webStyle.informContainer as React.CSSProperties}
          >
            <Grid
              className="first"
              item
              style={webStyle.informSecondContainerHead}
              xs={12}
            >
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.headerMain10}>
                    <span style={webStyle.HeadtopStyle22 as React.CSSProperties}>Daily KPI Report</span>
                  </FormLabel>

                </FormControl>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.informationSubject}
              xs={12}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.headerMain10}>
                  <span style={webStyle.labelStyleoptionhead as React.CSSProperties}>Calculation Type</span>
                </FormLabel>
                <RadioGroup aria-label="type" data-test-id="radiogroupInput" defaultValue="Slab" value={typing} row onChange={this.handleCalculationTypeChange}>
                  <FormControlLabel value="Slab" control={<Radio style={webStyle.themeColorback} />} label="Slab" style={{fontFamily: "Open Sans",fontSize:"14px",fontWeight:400,color:"#C6C6C6",marginTop:"10px"}} />
                  <FormControlLabel value="prorata" control={<Radio style={webStyle.themeColorback} />} style={{fontFamily: "Open Sans",fontSize:"14px",fontWeight:400,color:"#C6C6C6" ,marginTop:"10px"}} label="Pro rata" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.informationSubject}
              xs={12}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.headerMain10}>
                  <span style={webStyle.labelStyleoptionhead as React.CSSProperties}>Define Calculation Type</span>
                </FormLabel>
                {typing === "prorata" && (
                  <div className="form-block" id="signupBlock3">
                    <form>
                      <div
                        className="form-field"
                        id="signupform"
                        style={webStyle.topdescThirty}
                      >
                        <span style={webStyle.optionStyleTitle as React.CSSProperties}>Minimum Qualified Result (in %)</span>
                        <div
                          className="control"
                          id="signupfieldcontrol"
                          style={webStyle.marginLeft0}
                        >
                          <input
                            type="text"
                            data-test-id="txtInputEmail"
                            style={webStyle.inputField}
                            className="control-input"
                            placeholder="70%"
                            name="email"
                            id="pronounce-control"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                )}
                {typing === "Slab" && (
                  <div className="form-block" id="signupBlock3">
                    <form>
                      <Grid container xs={12} style={webStyle.gridAlignparent}>
                        <Grid item xs={2}>
                          <div
                            className="form-field"
                            id="signupform"
                            style={webStyle.topdescThirty}
                          >
                            <span style={webStyle.optionStyleTitle as React.CSSProperties}>No. of slabs</span>
                            <div
                              className="control"
                              id="signupfieldcontrol"
                              style={webStyle.marginLeft0}
                            >
                              <input
                                type="text"
                                data-test-id="txtInputEmail"
                                style={{ ...webStyle.inputFieldTabless,color:"#C6C6C6", marginBottom: "10px" ,backgroundColor:"#F3F4F5",textAlign:"end",fontFamily:"Open Sans",fontWeight:400,fontSize:"14px"}}
                                className="control-input"
                                name="email"
                                id="pronounce-control"
                                placeholder="1."
                                disabled
                              />
                              <input
                                type="text"
                                data-test-id="txtInputEmail"
                                style={{ ...webStyle.inputFieldTabless,color:"#C6C6C6", marginBottom: "0px" ,backgroundColor:"#F3F4F5",textAlign:"end",fontFamily:"Open Sans",fontWeight:400,fontSize:"14px"}}
                                className="control-input"
                                name="email"
                                id="pronounce-control"
                                placeholder="2."
                                disabled
                              />
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <div
                            className="form-field"
                            id="signupform"
                            style={webStyle.topdescThirty}
                          >
                            <span style={webStyle.optionStyleTitle as React.CSSProperties}>Min.</span>
                            <div
                              className="control"
                              id="signupfieldcontrol"
                              style={webStyle.marginLeft0}
                            >
                              <input
                                type="text"
                                data-test-id="txtInputEmail"
                                style={{ ...webStyle.inputFieldTabless, marginBottom: "10px" }}
                                className="control-input"
                                name="email"
                                id="pronounce-control"
                                disabled
                              />
                              <input
                                type="text"
                                data-test-id="txtInputEmail"
                                style={webStyle.inputFieldTabless}
                                className="control-input"
                                name="email"
                                id="pronounce-control"
                              />
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <div
                            className="form-field"
                            id="signupform"
                            style={webStyle.topdescThirty}>
                            <span style={webStyle.optionStyleTitle as React.CSSProperties}>Max.</span>
                            <div
                              id="signupfieldcontrol"
                              className="control"
                              style={webStyle.marginLeft0}>
                              <input
                                data-test-id="txtInputEmail"
                                type="text"
                                style={{ ...webStyle.inputFieldTabless, marginBottom: "10px" }}
                                name="email"
                                className="control-input"
                                disabled
                                id="pronounce-control"
                              />
                              <input
                                type="text"
                                style={webStyle.inputFieldTabless}
                                data-test-id="txtInputEmail"
                                name="email"
                                className="control-input"
                                id="pronounce-control"
                              />
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <div
                            style={webStyle.topdescThirty}
                            className="form-field"
                            id="signupform"
                          >
                            <span style={webStyle.optionStyleTitle as React.CSSProperties}>Weightage</span>
                            <div
                              className="control"
                              style={webStyle.marginLeft0}
                              id="signupfieldcontrol"
                            >
                              <input
                                data-test-id="txtInputEmail"
                                type="text"
                                style={{ ...webStyle.inputFieldTabless, marginBottom: "10px" }}
                                className="control-input"
                                name="email"
                                id="pronounce-control"
                                disabled
                              />
                              <input
                                data-test-id="txtInputEmail"
                                type="text"
                                className="control-input"
                                name="email"
                                id="pronounce-control"
                                style={webStyle.inputFieldTabless}
                              />
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <div
                            id="signupform"
                            className="form-field"
                            style={webStyle.topdescThirty}
                          >
                            <div
                              style={webStyle.marginLeft0}
                              className="control"
                              id="signupfieldcontrol"
                            >
                              <button
                                data-test-id="btnAdd"
                                style={webStyle.removeButton as React.CSSProperties}
                                type="button"
                                className="control-input"
                              >
                                Remove
                              </button>
                              <button
                                type="button"
                                data-test-id="btnRemove"
                                className="control-input"
                                style={webStyle.addButton as React.CSSProperties}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                )}
              </FormControl >
            </Grid >
            <Grid
              className="first"
              item
              style={webStyle.informatSubContainerTable}
              xs={12}
            >
              <>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={{...webStyle.headerMain10}}>
                    <span style={{ ...webStyle.labelStyleoptionhead as React.CSSProperties, fontWeight: 500}}>Applicable DKRs</span>
                  </FormLabel>
                </FormControl>
                {Dropdown === "Number" && (
                  <TableContainer style={webStyle.tableContainerMain}>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow style={webStyle.tableHeadcolormain}>
                          <TableCell style={webStyle.Tcell}><span style={{fontWeight:600}}>Sr.</span><span style={{fontWeight:600}}>No.</span></TableCell>
                          <TableCell style={webStyle.TableC}>DKR Type</TableCell>
                          <TableCell style={webStyle.TCell}>
                            DKR Brief Title
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                        >
                          <TableCell style={{...webStyle.TableCell4,width:"12%"}}>
                            1.
                          </TableCell>
                          <TableCell style={{...webStyle.Captureddkr,width:"14%"}}>
                            Captured DKR
                          </TableCell>
                          <TableCell>
                            <div className="form-block" id="signupBlock3">
                              <form>
                                <div
                                  className="form-field"
                                  id="signupform"                                >
                                  <div
                                    id="signupfieldcontrol"
                                    className="control"
                                    style={webStyle.marginLeft0}
                                  >
                                    <input
                                      type="text"
                                      style={{ ...webStyle.inputFieldTabless, width: "50%",color:"Black",textAlign:"start",fontFamily:"Open Sans",fontWeight:400,fontSize:"14px"}}
                                      placeholder="Todays Collection"
                                      data-test-id="txtInputEmail"
                                      className="control-input"
                                      name="email"
                                      id="pronounce-control"
                                    />
                                  </div>

                                </div>
                              </form>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow
                        >
                          <TableCell component="th" scope="row" style={webStyle.TableCell5}>
                            2.
                          </TableCell>
                          <TableCell style={webStyle.gamifieddkr}>
                            Gamified DKR
                          </TableCell>
                          <TableCell>
                            <div className="form-block" id="signupBlock3">
                              <form>
                                <div
                                  className="form-field"
                                  id="signupform"
                                >
                                  <div
                                    className="control"
                                    id="signupfieldcontrol"
                                    style={webStyle.marginLeft0}
                                  >
                                    <input
                                      type="text"
                                      data-test-id="txtInputEmail"
                                      style={{ ...webStyle.inputFieldTabless, width: "50%",color:"Black",textAlign:"start",fontFamily:"Open Sans",fontWeight:400,fontSize:"14px"}}
                                      className="control-input"
                                      placeholder="Total Collection"
                                      name="email"
                                      id="pronounce-control"
                                    />
                                  </div>

                                </div>
                              </form>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

                {Dropdown === "Percentage" && (
                  <TableContainer style={webStyle.tableContainerMain}>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow style={webStyle.tableHeadcolormain}>
                          <TableCell style={{...webStyle.Tcell,width:"12%"}}><span style={{fontWeight:600}}>Sr.</span><span style={{fontWeight:600}}>No.</span></TableCell>
                          <TableCell style={webStyle.TableC}>DKR Type</TableCell>
                          <TableCell style={webStyle.TCell}>
                            DKR Brief Title
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                        >
                          <TableCell style={webStyle.TableCell1}>
                            1.
                          </TableCell>
                          <TableCell style={webStyle.Captured}>
                            Captured DKR (Numerator)
                          </TableCell>
                          <TableCell>
                            <div className="form-block" id="signupBlock3">
                              <form>
                                <div
                                  className="form-field"
                                  id="signupform"                                >
                                  <div
                                    className="control"
                                    id="signupfieldcontrol"
                                    style={webStyle.marginLeft0}
                                  >
                                    <input
                                      type="text"
                                      style={{ ...webStyle.inputFieldTabless, width: "55%",color:"Black",textAlign:"start",fontFamily:"Open Sans",fontWeight:400,fontSize:"14px"}}
                                      data-test-id="txtInputEmail"
                                      placeholder="Todays Collection"
                                      className="control-input"
                                      id="pronounce-control"
                                      name="email"
                                    />
                                  </div>

                                </div>
                              </form>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow
                        >
                          <TableCell component="th" scope="row" style={webStyle.TableCell2}>
                            2.
                          </TableCell>
                          <TableCell style={webStyle.Capdenominator}>
                            Captured DKR (Denominator)
                          </TableCell>
                          <TableCell>
                            <div className="form-block" id="signupBlock3">
                              <form>
                                <div
                                  id="signupform"
                                  className="form-field"
                                >
                                  <div
                                    id="signupfieldcontrol"
                                    className="control"
                                    style={webStyle.marginLeft0}
                                  >
                                    <input
                                      data-test-id="txtInputEmail"
                                      type="text"
                                      className="control-input"
                                      style={{ ...webStyle.inputFieldTabless, width: "55%",color:"Black",textAlign:"start",fontFamily:"Open Sans",fontWeight:400,fontSize:"14px"}}
                                      name="email"
                                      placeholder="Total Collection"
                                      id="pronounce-control"
                                    />
                                  </div>

                                </div>
                              </form>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow
                        >
                          <TableCell component="th" scope="row" style={webStyle.TableCell3}>
                            3.
                          </TableCell>
                          <TableCell style={webStyle.Gamified}>
                            Gamified DKR
                          </TableCell>
                          <TableCell>
                            <div className="form-block" id="signupBlock3">
                              <form>
                                <div
                                  id="signupform"
                                  className="form-field"
                                >
                                  <div
                                    id="signupfieldcontrol"
                                    className="control"
                                    style={webStyle.marginLeft0}
                                  >
                                    <input
                                      type="text"
                                      data-test-id="txtInputEmail"
                                      placeholder="Total Collection"
                                      style={{ ...webStyle.inputFieldTabless, width: "55%",color:"Black",textAlign:"start",fontFamily:"Open Sans",fontWeight:400,fontSize:"14px"}}
                                      name="email"
                                      id="pronounce-control"
                                      className="control-input"
                                    />
                                  </div>

                                </div>
                              </form>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </>
            </Grid>
          </Grid >

          <Grid style={webStyle.buttonmainContainer}>
            <Box style={{ marginTop: '50px', marginBottom: '40px' }}>
              <Button style={webStyle.SubmitBtn as React.CSSProperties}>
                Submit
              </Button>
              <Button variant="outlined" style={webStyle.CancelBtn as React.CSSProperties} >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Box >
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  Editbutton:{
    fontFamily: 'Open Sans', 
    backgroundColor: "FDD100", 
    color: "black", 
    padding: "15px 20px 15px 20px", 
    borderRadius: "10px", 
    width: "200px", 
    fontSize: "16px", 
    fontWeight: 600, 
    textTransform: "none"
  },
  gamifieddkr: {
    fontSize: "14px",
    fontFamily: "Open Sans",
    color: "black"
  },
  TableCell5: {
    fontFamily: "Open Sans",
    color: "black",
    fontSize: "14px",
    paddingLeft: "65px",
  },
  Captureddkr: {
    fontFamily: "Open Sans",
    color: "black",
    fontSize: "14px",
    fontWeight: 400
  },
  TableCell4: {
    fontFamily: "Open Sans",
    color: "black",
    paddingLeft: "65px",
    fontSize: "14px",
  },
  Gamified: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    width: "20%",
    color: "black",
    fontWeight: 400
  },
  TableCell3: {
    fontSize: "14px",
    fontFamily: "Open Sans",
    paddingLeft: "65px",
    color: "black",
  },
  Capdenominator: {
    color: "black",
    fontFamily: "Open Sans",
    fontSize: "14px",
    width: "20%"
  },
  TableCell2: {
    color: "black",
    fontFamily: "Open Sans",
    paddingLeft: "65px",
    fontSize: "14px",
  },
  Captured: {
    fontSize: "14px",
    color: "black",
    fontFamily: "Open Sans",
    width: "20%"
  },
  TableCell1: {
    fontSize: "14px",
    fontFamily: "Open Sans",
    paddingLeft: "65px",
    color: "black",
  },
  CancelBtn: {
    fontWeight: 600,
    fontFamily: 'Open Sans',
    width: "170px",
    fontSize: "16px",
    textTransform: "none",
    backgroundColor: "white",
    color: "black",
  },
  SubmitBtn: {
    fontFamily: 'Open Sans',
    backgroundColor: "FDD100",
    marginRight: "20px",
    color: "black",
    fontSize: "16px",
    width: "170px",
    fontWeight: 600,
    textTransform: "none",
    
  },
  TCell: {
    fontSize: "14px",
    fontFamily: "Open Sans",
    color: "black",
    fontWeight: 600
  },
  TableC: {
    fontSize: "14px",
    fontFamily: "Open Sans",
    color: "black",
    fontWeight: 600
  },
  Tcell: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    paddingLeft: "35px",
    color: "black",
    fontWeight:400,
  },
  maincontainer: {
    height: "64px",
    background: "#F3F4F5",
    marginRight: "-20px"
  },
  informContainer: {
    padding: "0 32px",
    alignItems: "baseline",
    flexDirection: "column"
  },
  informationSubject: {
    width: "800px",
    marginTop: "24px",
  },
  leftSide: {
    padding: "19px 0px 21px 38px"
  },
  nullContainer: {
    padding: "0px 44px 0px 0px"
  },

  informatSubContainerTable: { marginTop: "24px", width: "90%" },
  informSecondContainerHead: {
    marginTop: "24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "initial",
    width: "90%"
  },
  topdescThirty: {
    marginTop: "28px",
  },
  container: {
    height: "100%",
    width: "100%",
  },
  optionStyleTitle: {
    color: "black",
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "Open Sans"
  },
  labelStyleoptionhead: {
    color: "black",
    fontWeight: 500,
    fontSize: "22px",
    fontFamily: "Open Sans"
  },
  TopstyleHeader: {
    fontSize: "18px",
    color: "#c8ccc9",
    fontWeight: 700,
    fontFamily: "Open Sans"
  },
  blurvalueStyle: {
    color: "black",
    marginTop: "10px",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: 400
  },
  rowarrowDisplay: {
    alignItems: "center",
    display: "flex",
  },
  HeadStyleTop1: {
    color: "black",
    fontFamily: "Open Sans",
    fontSize: "18px",
  },
  HeadtopStyle22: {
    color: "black",
    fontWeight: 700,
    fontSize: "24px",
    fontFamily: "Open Sans"
  },
  inputField: {
    width: "60%",
    border: '0.5px solid #979797',
    background: 'transparent',
  },
  inputFieldTabless: {
    width: "70%",
    border: '0.5px solid #979797',
    background: 'transparent',
  },
  marginLeft0: {
    marginLeft: 0,
    marginTop: 10
  },
  removeButton: {
    background: "transparent",
    borderColor: '#FF5C5C',
    margin: "10px 0 0",
    width: "70%",
    fontSize: "15px",
    fontWeight: 600,
    fontFamily: "Open Sans"
  },
  addButton: {
    background: "transparent",
    borderColor: '#FDD100',
    margin: "10px 0 0",
    width: "70%",
    fontSize: "15px",
    fontWeight: 600,
    fontFamily: "Open Sans"
  },
  buttonmainContainer: { display: "flex", gap: "20px", margin: "0 40px" },
  themeColorback: {
    color: "#FDD100",
    fontFamily: "Open sans",
    fontWeight: 400,
    fontSize: "14px"
  },
  headerMain10: {
    display: "flex",
    alignItems: "baseline",
    gap: "4px",
    MarginTop: "30px"
  },
  tableHeadcolormain: {
    backgroundColor: "#F5F5F5",
  },
  tableContainerMain: {
    border: "1px solid #979797",
    borderRadius: "10px",
    marginTop: "15px"
  },
  gridAlignparent: {
    alignItems: "end"
  },
};
// Customizable Area End
