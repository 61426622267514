import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import toast from "react-hot-toast";
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: string
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  type: string;
  description: string;
  automatic: boolean;
  runRate: boolean;
  analytics: boolean;
  goaltype: string;
  isModal:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddKpiController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      type: "core",
      description: "",
      automatic: false,
      runRate: false,
      analytics: false,
      goaltype: "number",
      isModal:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start


    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.errors) {
          let message: string;
          const error = responseJson.errors[0];

          if (typeof error === 'string') {
            message = error;
            toast.error(message);
          }
        }
      }
    }
    // Customizable Area End
  }

  // web events
  handleChange = (event: React.ChangeEvent<{
    name?: string | undefined;
    value: string;
  }>) => {
    this.setState({
      type: event.target.value
    });
  };
  handleDescriptionChange = (event: React.ChangeEvent<{
    name?: string | undefined;
    value: string;
  }>) => {
    this.setState({ description: event.target.value })
  };
  handleAutomatic = () => {
    this.setState({ automatic: !this.state.automatic })
  }
  handleRunrate = () => {
    this.setState({ runRate: !this.state.runRate })
  }
  handleAnalytics = () => {
    this.setState({ analytics: !this.state.analytics })
  }
  handleGoal = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ goaltype: event.target.value })
  }
  // Customizable Area Start
  // Customizable Area End
}
